import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import SliderButton from '../../SliderEasySplit/SliderButton';
import './HomeSlider.scss';

const homeData = [
  {
    id: 0,
    image: {
      src: '/img/home/industry-shoe-desktop.png',
      srcSet:
        '/img/home/industry-shoe-mb.png 768w, /img/home/industry-shoe-desktop.png 1024w',
    },
    title: 'Ecommerce stores and marketplaces',
    desc:
      'Accept customer payments on the website, in-app or at the doorstep, process online and COD refunds, vendor payouts, and more.',
    href: 'https://www.cashfree.com/solutions/ecommerce-payment-gateway/',
  },
  {
    id: 1,
    image: {
      src: '/img/home/industry-dektop.png',
      srcSet:
        '/img/home/industry-mobile.png 768w, /img/home/industry-dektop.png 1024w',
    },
    title: 'Lending and microfinance platforms',
    desc:
      'Disburse instant loans, accept deposits and automate recurring loan repayment collection.',
    href: 'https://www.cashfree.com/nbfc-digital-lending-solutions/',
  },
  {
    id: 2,

    image: {
      src: '/img/home/industry3-desktop.png',
      srcSet:
        '/img/home/industry3-mb.png 768w, /img/home/industry3-desktop.png 1024w',
    },
    title: 'Insurers, web aggregators, brokers and TPAs.',
    desc:
      'Sell policies, collect premiums on app or WhatsApp using payment links, verify policy holders’ account details, disburse claims, pay to agents and other intermediaries.',
    href: 'https://www.cashfree.com/solutions/insurance/',
  },
  {
    id: 3,
    image: {
      src: '/img/home/industry4.png',
      srcSet: '/img/home/industry4-mb.png 768w, /img/home/industry4.png 1024w',
    },
    title: 'Educational Institutions, edtechs, and ERPs',
    desc:
      'Collect and reconcile fees, pay salaries, disburse expenses, and other payments.',
    href: 'https://www.cashfree.com/solutions/education/',
  },
];

function HomeSlider({ data, settings }) {
  return (
    <div className="md:ml-3.5 pb-[80px] md:pb-0 home-slider">
      <Slider {...settings}>
        {data.map((d) => (
          <div key={d.id} className="pl-4 pr-2 !flex h-full">
            <div
              className="column link-hover"
              style={{ paddingLeft: '0px', paddingRight: '0px' }}>
              <div className="h-full bg-cf-light-grey flex flex-col">
                <div className="relative">
                  <img
                    {...d?.image}
                    alt=""
                    width="400px"
                    style={{ height: '100%', objectFit: 'cover' }}
                  />
                </div>
                <div className="p-4md md:px-6 flex flex-col justify-between h-full flex-1">
                  <div>
                    <h4
                      className="font-body font-semibold text-[16px] leading-6"
                      style={{ margin: '0px 0 10px', lineHeight: '24px' }}>
                      {d.title}
                    </h4>
                    <p className="text-2.5sm md:text-base">{d.desc}</p>
                  </div>
                  <a
                    className=" text-cf-green font-semibold text-2.5sm pt-6 md:pt-10"
                    href={d.href}
                    style={{ position: 'relative', bottom: 0 }}>
                    Learn more <span className="chevron" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

HomeSlider.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      image: PropTypes.shape({}),
      title: PropTypes.string,
      background: PropTypes.string,
    }),
  ),
  imageBaseUrl: PropTypes.string,
  settings: PropTypes.shape({}),
};

HomeSlider.defaultProps = {
  data: homeData,
  imageBaseUrl: '',
  settings: {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    // slidesToScroll: 1,
    nextArrow: <SliderButton left={false} />,
    prevArrow: <SliderButton />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.2,
          infinite: false,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          infinite: false,
          arrows: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          infinite: false,
          arrows: true,
          dots: false,
        },
      },
    ],
  },
};
export default HomeSlider;
