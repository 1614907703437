import React from 'react';

export const faqData = [
  {
    id: 0,
    q: 'Why should I choose Cashfree Payments?',
    a: (
      <>
        <p>
          Cashfree Payments is India’s leading payments and API banking company.
          It helps 3,00,000+ businesses accept and send money and is used for
          multiple use cases like vendor payouts, wage payouts, bulk refunds,
          etc.
        </p>

        <p>
          Cashfree Payments offers the widest range of solutions including API
          banking platform{' '}
          <a className="external-link" href="https://www.cashfree.com/payouts/">
            Payouts
          </a>{' '}
          that helps businesses send money instantly 24x7 to any bank accounts,
          UPI ID, card, or wallet with a simple API that businesses can
          integrate with their product, instead of uploading complicated files
          on corporate banking portal.
        </p>

        <p>
          Cashfree Payments also offers an advanced{' '}
          <a
            className="external-link"
            href="https://www.cashfree.com/payment-gateway-india/">
            payment gateway{' '}
          </a>
          with features like{' '}
          <a
            className="external-link"
            href="https://www.cashfree.com/instant-refunds/">
            instant refunds
          </a>
          ,{' '}
          <a
            className="external-link"
            href="https://www.cashfree.com/preauthorization/">
            card-preauthorization
          </a>
          , etc among other products.
        </p>

        <p>
          Cashfree Payments is integrated with major platforms like{' '}
          <a
            className="external-link"
            href="https://www.cashfree.com/shopify-payment-gateway-india/">
            Shopify
          </a>
          ,{' '}
          <a
            className="external-link"
            href="https://docs.cashfree.com/docs/payment-gateway-dashboard#paypal">
            Paypal
          </a>
          , Amazon Pay, Google Pay, and Ola money (wallet and postpaid both)
          among others.
        </p>
      </>
    ),
  },
  {
    id: 1,
    q: 'How to get started on Cashfree Payments?',
    a: (
      <>
        <p>
          We do 100% paperless onboarding for merchants on Cashfree Payments.
          Follow the below steps for quick account activation and start using
          Cashfree Payments in no time:
        </p>
        <p>
          1.{' '}
          <a
            className="external-link"
            href="https://merchant.cashfree.com/merchants/signup">
            Create your account
          </a>{' '}
          on Cashfree Payments
        </p>
        <p>
          2. Update your business profile and upload scanned copies of business
          documents
        </p>
        <p>
          3. Choose the product you are interested in. You can switch to Test
          Environment and try out the product features and integrations using
          test API credentials. Once ready, you can switch to Production
          Environment.{' '}
        </p>
        <p>
          4. Once you create your account, our payment expert will call you in
          the next 24 working hours, share your business requirement and we will
          help you pick the right set of features.
        </p>
        <p>
          5. Once the account is verified, you will receive an account
          activation confirmation email. For more details refer to our{' '}
          <a
            className="external-link"
            href="https://docs.cashfree.com/docs/activate-account">
            account activation guide
          </a>
          .{' '}
        </p>
      </>
    ),
  },
  {
    id: 2,
    q: 'How long would it take to activate my account?',
    a: (
      <>
        <p>
          Once your documents are submitted for review, account gets activated
          with 24 working hours. Please refer to our{' '}
          <a
            className="external-link"
            href="https://docs.cashfree.com/docs/activate-account">
            quick activation guide
          </a>
          . You can also write to us at{' '}
          <a className="external-link" href="mailto:care@cashfree.com">
            care@cashfree.com
          </a>
          .
        </p>
      </>
    ),
  },
  {
    id: 3,
    q:
      'How is Cashfree Payment Gateway different from other payment gateways in India?',
    a: (
      <>
        <p>
          <a
            className="external-link"
            href="https://www.cashfree.com/payment-gateway-india/">
            Cashfree Payment Gateway
          </a>{' '}
          offers 120+ payment modes including Pay Later, Paytm, and Cardless EMI
          options etc. Cashfree Payment Gateway is the only payment gateway that
          supports{' '}
          <a
            className="external-link"
            href="https://www.cashfree.com/instant-refunds/">
            instant refunds
          </a>{' '}
          versus the industry standard of refunds in 5-7 working days - this
          ensures Cashfree Payments merchants are able to delight their
          customers while running business operations smoothly.
        </p>
      </>
    ),
  },
  {
    id: 4,
    q: 'Can I start accepting international payments using Cashfree Payments?',
    a: (
      <>
        <p>
          Yes, it is possible to enable international payments on Cashfree
          Payment Gateway. You can talk to us and help us understand your
          business for quick activation. If you have a Paypal account,{' '}
          <a
            className="external-link"
            href="https://docs.cashfree.com/docs/payment-gateway-dashboard#paypal">
            connect Paypal to your Cashfree Payments account
          </a>{' '}
          and start offering Paypal as a checkout option on the payment page
          instantly.
        </p>
      </>
    ),
  },
  {
    id: 5,
    q: 'What is Payouts?',
    a: (
      <>
        <p>
          <a className="external-link" href="https://www.cashfree.com/payouts/">
            Payouts
          </a>{' '}
          by Cashfree Payments is a payment disbursal platform used by
          businesses to do bulk payouts. When you create a Payouts account,
          Cashfree Payments creates a virtual wallet for you. You can add funds
          from your bank accounts to the wallet and use the funds to do payouts.
          You can use the Payouts dashboard to do single or bulk payouts via
          excel upload or integrate Payouts APIs with your product or ERP to
          automate bulk payouts.
        </p>
      </>
    ),
  },
  {
    id: 6,
    q: 'Can I get tailored pricing for my business?',
    a: (
      <>
        <p>
          Yes, you can connect with our team to discuss your business
          requirement and get tailored pricing depending on the nature of your
          business and the volume of transactions. For more details,{' '}
          <a
            className="external-link"
            href="https://www.cashfree.com/contact-sales/?source-action=Home%20page">
            contact us
          </a>{' '}
          or visit the{' '}
          <a
            className="external-link"
            href="https://www.cashfree.com/payment-gateway-charges/">
            pricing page
          </a>
          .
        </p>
      </>
    ),
  },
];
export const quotes = [
  {
    id: 0,
    image: '/img/varun-full.jpg',
    isVideo: true,
    companyLogo: {
      src: '/img/furlenco.svg',
      width: '108',
      height: '44',
      alt: 'furlenco',
    },
    name: 'Varun Krishnakumar',
    designation: 'Director of Products, Furlenco',
    quote:
      'At Furlenco we use Cashgram for instant security deposit refunds. Since Cashgram we have seen a phenomenal change incustomer satisfaction, the moment subscription is closed, Cashgram takes care of the rest',
  },
  {
    id: 1,
    image: '/img/anoj.jpg',
    companyLogo: {
      src: '/img/milaap.svg',
      width: '91',
      height: '39',
      alt: 'milaap',
    },
    name: 'Anoj Viswanathan',
    designation: 'Co-founder, Milaap',

    quote:
      'Cashfree has been a solid partner to rely on for our payment solutions and we are a heavy user of Payouts and Auto Collect. The best part is they are very customer centric and provide solutions with small TAT.',
  },
  {
    id: 3,
    image: '/img/mansi-full.jpg',
    companyLogo: {
      src: '/img/noise.png',
      width: '91',
      height: '39',
      alt: 'noise',
    },
    name: 'Mansi Saxena',
    designation: 'Manager- Business Operations, Noise',

    quote:
      'Cashfree gives us next day settlements. For a startup like us it means money reaches our account without being blocked anywhere.',
  },
];

export const logoData = [
  {
    id: 0,
    src: 'zomato.svg',
    width: '91',
    height: '19',
    className: 'max-w-[130px]',
    alt: 'zomato',
  },
  {
    id: 1,
    src: 'cred-black.svg',
    width: '79',
    height: '36',
    className: 'max-w-[100px]',
    alt: 'cred',
  },
  {
    id: 2,
    src: 'nykaa-dark-s.svg',
    width: '61',
    height: '20',
    className: 'max-w-[60px]',
    alt: 'nykaa',
  },
  {
    id: 3,
    src: 'delhivery.svg',
    width: '97',
    height: '15',
    className: 'max-w-[122px]',
    alt: 'delhivery',
  },
  {
    id: 4,
    src: 'acko.svg',
    width: '67',
    height: '22',
    className: 'max-w-[77px]',
    alt: 'acko',
  },
  {
    id: 5,
    src: 'shell.svg',
    width: '72',
    height: '36',
    className: 'max-w-[72px]',
    alt: 'shell',
  },
  {
    id: 6,
    src: 'zoomcar.svg',
    width: '103',
    height: '23',
    className: 'max-w-[115px]',
    alt: 'zoomcar',
  },
  {
    id: 7,
    src: 'medlife.svg',
    width: '70',
    height: '36',
    className: 'max-w-[70px]',
    alt: 'medlife',
  },
  {
    id: 8,
    src: 'dunzo-black.svg',
    width: '96',
    height: '21',
    className: 'max-w-[104px]',
    alt: 'dunzo',
  },
  {
    id: 9,
    src: 'dream-11.svg',
    width: '99',
    height: '27',
    className: 'max-w-[130px]',
    alt: 'dream11',
  },
  {
    id: 10,
    src: 'lt-financial.svg',
    width: '180',
    height: '32',
    className: 'max-w-[141px]',
    alt: 'lt-financial',
  },
];
