import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import SliderButton from '../../SliderEasySplit/SliderButton';
import './PartnerQuoteSlider.scss';

const partners = [
  {
    id: 0,
    image: '/img/partner/gaurav.png',
    companyLogo: {
      src: '/img/partner/prinknest.png',
      width: '58',
      height: '41',
      alt: 'Prinknest Technology LLP',
    },
    name: 'Gaurav Mishra',
    designation: 'CEO, Prinknest Technology LLP',

    quote:
      'We are working as a partner with Cashfree for a while, and offering their solutions to our clients. Cashfree has products for all your payment collection related requirements.  We & our clients both are happy on their fast settlement, costing & payment related support.',
  },
  {
    id: 1,
    image: '/img/partner/anand.png',
    companyLogo: {
      src: '/img/partner/nileegame.png',
      width: '103',
      height: '20',
      alt: 'Nilee Games & Future Technologies Pvt. Ltd.',
    },
    name: 'Anand Jha',
    designation: 'Director, Nilee Games & Future Technologies Pvt. Ltd.',

    quote:
      'Cashfree really helped us to focus on our core business rather than worrying about payment issues and service interruptions. Their commercials are very competitive within the market. We are using Cashfree’s PG and Payout product. Their support staff are very attentive and ensure they understand our requirements exactly and deliver.',
  },
  {
    id: 2,
    image: '/img/partner/vijay.png',
    companyLogo: {
      src: '/img/partner/baagdi.png',
      width: '72',
      height: '29',
      alt: 'Baagdi Solutions',
    },
    name: 'Rashtra Vijay Choudhary',
    designation: 'Managing Director, Baagdi Solutions',

    quote:
      'Here in baagdi we were looking for a new payment gateway provider, we did some research and looked around at the different pricing and type of service offerings. We found Cashfree to be the best in both,and decided to partner. Our daily interaction with the product is all done via the internet. and almost all payments are done automatically through the website using Cashfree. Support team is very dedicated. Now many of our clients have started using Cashfree.',
  },
  {
    id: 3,
    image: '/img/partner/jun.png',
    companyLogo: {
      src: '/img/partner/advance.png',
      width: '93',
      height: '11',
      alt: 'Advance.Al',
    },
    name: 'Jun Zhang',
    designation: 'India Head of Partnership & Strategy, Advance.Al',

    quote:
      "Cashfree has an NBFC-specific payment processing suite which easily integrates ADVANCE.AI's KYC and anti-fraud technology that leverages big data and machine learning to deliver best-in class-products. This collaboration will help us tap the Indian market and meet the demand by Indian fintechs and NBFCs for easier and faster processing of loan disbursals.",
  },
  {
    id: 4,
    image: '/img/partner/akash.png',
    companyLogo: {
      src: '/img/partner/billeasy.png',
      width: '55',
      height: '32',
      alt: 'Billeasy',
    },
    name: 'Akash Patil',
    designation: 'CEO, Billeasy',

    quote:
      'Cashfree enables us to provide easy payment solutions for many of our online and offline clientele. Their response towards account setup and other requests have been very prompt. Their product offering is strong and reliable across channels.',
  },
];

function PartnerQuoteSlider({ data, settings, OpenVideoModalToggle }) {
  const [activeSlide, setActiveSlide] = useState(0);
  const slider = useRef(null);
  const onPrev = () => {
    slider?.current?.slickPrev();
  };
  return (
    <div className="partner-quote-slider min-h-[400px] flex flex-wrap text-left relative">
      <SliderButton
        className={`absolute hidden md:flex top-1/2 -left-6 ${
          (activeSlide === 0 && 'slick-disabled') || ''
        }`}
        disabled={activeSlide === 0}
        onClick={onPrev}
      />
      <div className="w-full md:w-1/2 relative hidden md:flex min-h-[350px] md:min-h-[390px] overflow-hidden">
        {data.map((d, index) => (
          <img
            key={d.id}
            src={d.image}
            alt={d.name}
            className={`absolute left-0 top-0 object-cover w-full h-full opacity-0 transition-opacity ${
              activeSlide === index ? 'opacity-100' : ''
            }`}
          />
        ))}
      </div>
      <div className="w-full md:w-1/2 md:px-[24px] xl:pl-[38px] relative self-center pt-4 pb-12 md:py-0">
        <Slider
          {...settings}
          beforeChange={(_, nextIndex) => {
            setActiveSlide(nextIndex);
          }}
          ref={slider}>
          {data.map((d) => (
            <div key={d.id} className="item">
              <div className="flex flex-col">
                <div className="block md:hidden overflow-hidden mb-4">
                  <img
                    key={d.id}
                    src={d.image}
                    alt={d.name}
                    className="object-cover w-full h-[250px] object-right"
                  />
                </div>
                <div className="">
                  <div className="text-md md:text-3md font-medium mb-[27px] md:pr-8  md:mb-[40px]">
                    “{d.quote}”
                    {d.isVideo && (
                      <button
                        className="text-cf-green link mt-[10px] flex watchnow_btn"
                        type="button"
                        onClick={OpenVideoModalToggle}>
                        Watch Video <span className="chevron" />
                      </button>
                    )}
                  </div>
                </div>

                <img alt="" {...d.companyLogo} className="md:mb-2.5" />
                <div className="text-2sm md:text-md">
                  <div className="font-bold">{d.name}</div>
                  <div>{d.designation}</div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

PartnerQuoteSlider.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      image: PropTypes.string,
      companyLogo: PropTypes.shape({
        src: PropTypes.string,
        width: PropTypes.string,
        height: PropTypes.string,
        alt: PropTypes.string,
      }),
      name: PropTypes.string,

      designation: PropTypes.string,
      quote: PropTypes.string,
    }),
  ),
  settings: PropTypes.shape({}),
  OpenVideoModalToggle: PropTypes.string,
};

PartnerQuoteSlider.defaultProps = {
  data: partners,
  OpenVideoModalToggle: '',
  settings: {
    swipe: false,
    dots: true,
    arrows: true,
    infinite: false,
    autoplay: false,
    speed: 500,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SliderButton left={false} />,
    prevArrow: <SliderButton />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          swipe: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          arrows: false,
          dots: true,
        },
      },
    ],
  },
};

export default PartnerQuoteSlider;
